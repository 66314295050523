	/*
  	Flaticon icon font: Flaticon
  	Creation date: 19/03/2020 11:50
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-pizza-slice:before { content: "\f100"; }
.flaticon-burger:before { content: "\f101"; }
.flaticon-cola:before { content: "\f102"; }
.flaticon-french-fries:before { content: "\f103"; }
.flaticon-french-fries-1:before { content: "\f104"; }
.flaticon-hot-dog:before { content: "\f105"; }
.flaticon-cookies:before { content: "\f106"; }
.flaticon-sandwich:before { content: "\f107"; }